import classNames from "classnames"
import React from "react"

import type { TitleProps } from "@bounce/design-system"
import { Paragraph1, Title2 } from "@bounce/design-system"
import type { Nullable } from "@bounce/util"

import type { StepsProps } from "./Steps"
import { Steps } from "./Steps"

export type HowItWorksProps = {
  title?: Nullable<string>
  subtitle?: Nullable<string>
  titleLevel?: TitleProps["level"]
  children?: React.ReactNode
  sectionRef?: React.Ref<HTMLElement>
  imageClassName?: string
  className?: string
} & Pick<StepsProps, "steps">

export const HowItWorks = ({
  title,
  subtitle,
  steps,
  titleLevel = 2,
  children,
  sectionRef,
  imageClassName,
  className,
}: HowItWorksProps) => (
  <section
    ref={sectionRef}
    className={classNames("w-full bg-neutral-100 pt-12 pb-section md:pt-16 xl:pt-20", className)}>
    <div className="mx-auto w-full max-w-screen-xl px-6">
      <Title2 level={titleLevel}>{title}</Title2>
      {!!subtitle && <Paragraph1 className="mt-2 max-w-4xl text-neutral-800 md:mt-2.5 xl:mt-3">{subtitle}</Paragraph1>}
      <Steps
        steps={steps}
        titleLevel={titleLevel === 3 ? 4 : 3}
        className="mt-8 md:mt-9 xl:mt-12"
        imageClassName={imageClassName}
      />
      {children}
    </div>
  </section>
)
