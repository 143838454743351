import classNames from "classnames"
import React from "react"

import { Paragraph1, Title5 } from "@bounce/design-system"

import type { PNImageProps } from "../PNImage/PNImage"
import { PNImage } from "../PNImage/PNImage"

export type Step = {
  src?: PNImageProps["src"]
  title?: string | React.ReactNode
  description?: string | React.ReactNode
}

export type StepsProps = {
  steps: Step[]
  titleLevel?: 3 | 4
  imageClassName?: string
  className?: string
}

export const Steps = ({ steps, titleLevel = 3, imageClassName, className }: StepsProps) => (
  <ul
    className={classNames(
      className,
      "grid w-full grid-cols-1 gap-x-6 gap-y-8 md:gap-y-7 xl:grid-flow-col xl:grid-cols-none",
    )}>
    {steps.map(({ src, title, description }, i) => (
      <li key={i} className="flex flex-col md:flex-row xl:flex-col">
        {!!src && (
          <PNImage
            src={src}
            height={232}
            width={232}
            loading="lazy"
            imgixParams={{ fit: "max", h: 232 }}
            className={classNames("h-[232px] w-[232px] object-contain xl:h-[246px] xl:w-[246px]", imageClassName)}
          />
        )}
        {(!!title || !!description) && (
          <div className={classNames(!!src && "mt-11 md:ml-6 md:mt-6 xl:ml-0 xl:mt-12")}>
            <Title5 level={titleLevel}>{title}</Title5>
            <Paragraph1 className="mt-3.5 text-neutral-800 xl:mt-4">{description}</Paragraph1>
          </div>
        )}
      </li>
    ))}
  </ul>
)
