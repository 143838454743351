import classNames from "classnames"
import React from "react"

import { Paragraph1, Title2 } from "@bounce/design-system"
import type { Nullable } from "@bounce/util"

import type { FAQItemProps } from "./FAQItem"
import { FAQList } from "./FAQList"
import type { FAQ } from "./types"

export type FAQsProps = {
  faqs: FAQ[]
  title?: Nullable<React.ReactNode>
  subtitle?: Nullable<string>
  titleClassName?: string
  className?: string
  listClassName?: string
  sectionRef?: React.Ref<HTMLElement>
  id?: string
} & Pick<FAQItemProps, "onClickItem">

export const FAQs = ({
  id,
  faqs,
  className,
  title,
  titleClassName,
  listClassName,
  subtitle,
  sectionRef,
  onClickItem,
}: FAQsProps) => {
  const hasHeader = !!title || !!subtitle

  return (
    <section id={id} ref={sectionRef} className={classNames("relative flex w-full flex-col", className)}>
      {hasHeader && (
        <header className="mb-16 w-full">
          <div className="mx-auto w-full max-w-screen-xl px-6">
            {!!title && <Title2 className={["max-w-[600px] text-black", titleClassName]}>{title}</Title2>}
            {!!subtitle && (
              <Paragraph1 className="mt-2 max-w-4xl text-neutral-800 md:mt-2.5 xl:mt-3">{subtitle}</Paragraph1>
            )}
          </div>
        </header>
      )}
      <FAQList
        faqs={faqs}
        onClickItem={onClickItem}
        className={classNames(listClassName, "border-t-2 border-b-neutral-300")}
      />
    </section>
  )
}
